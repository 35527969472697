<template>
  <b-card v-if="data" class="card-tiny-line-stats" body-class="pb-50">
    <h6>Realtime Data Kapal</h6>
    <!-- <b-avatar
      variant="light-success"
      size="45"
    >
      <feather-icon
        size="21"
        icon="AnchorIcon"
      />
    </b-avatar> -->
    <!-- chart -->
    <vue-apex-charts
      height="430"
      :options="statisticsProfit.chartOptions"
      :series="data.series"
    />
    <b-card-text class="font-small-2 mr-25 mb-0">
      <strong>Updated this today for 7 days ago</strong>
    </b-card-text>
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'

const $trackBgColor = '#EBEBEB'

export default {
  components: {
    BCard,
    VueApexCharts
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      statisticsProfit: {
        chartOptions: {
          chart: {
            stacked: false,
            type: 'line',

            toolbar: {
              show: true
            },
            zoom: {
              enabled: true
            }
          },
          grid: {
            borderColor: '#111',
            strokeDashArray: 5,
            xaxis: {
              lines: {
                show: true
              }
            },
            yaxis: {
              lines: {
                show: true
              }
            },
            padding: {
              // top: -30,
              // bottom: -10,
            }
          },
          stroke: {
            width: 3,
            curve: 'smooth'
            // dashArray: 4,
          },
          fill: {
            type: 'gradient' / 'solid' / 'pattern' / 'image'
          },
          colors: [
            $themeColors.info,
            $themeColors.warning,
            $themeColors.success,
            $themeColors.danger
          ],
          markers: {
            size: 4,
            colors: $themeColors.info,
            strokeColors: $themeColors.info,
            strokeWidth: 4,
            strokeOpacity: 1,
            strokeDashArray: 2,
            fillOpacity: 1,
            discrete: [
              {
                seriesIndex: 0,
                dataPointIndex: 1,
                fillColor: '#000',
                strokeColor: $themeColors.info,
                size: 1
              }
            ],
            shape: 'circle',
            radius: 4,
            hover: {
              size: 10
            }
          },
          xaxis: {
            categories: this.data?.series[0]?.categories ?? [
              'Senin, 25 Juli',
              'Selasa',
              'Rabu',
              'Kamis',
              'Jumat',
              'Sabtu'
            ],
            labels: {
              show: true,
              style: {
                // colors: '#FFD95E5A',
                fontSize: '0.86rem',
                fontFamily: 'Montserrat'
              }
            },
            axisBorder: {
              show: true
            },
            axisTicks: {
              show: true
            }
          },
          yaxis: {
            show: true,
            labels: {
              style: {
                colors: 'success',
                fontSize: '0.86rem',
                fontFamily: 'Montserrat'
              }
            }
          },
          legend: {
            show: true
            // onItemHover: {
            //   highlightDataSeries: true,
            // },
            // onItemClick: {
            //   toggleDataSeries: true,
            // },
            // position: 'top',
          },
          dataLabels: {
            enabled: false
            // enabledOnSeries: false,
            // formatter(val, opts) {
            //   return val
            // },
            // textAnchor: 'middle',
            // distributed: true,
            // offsetX: 0,
            // offsetY: 0,
            // style: {
            //   fontSize: '14px',
            //   fontFamily: 'Helvetica, Arial, sans-serif',
            //   fontWeight: 'bold',
            //   colors: ['#fff'],
            // },
            // background: {
            //   enabled: false,
            //   foreColor: '#fff',
            //   padding: 4,
            //   borderRadius: 2,
            //   borderWidth: 1,
            //   borderColor: '#fff',
            //   opacity: 0.9,
            //   dropShadow: {
            //     enabled: false,
            //     top: 1,
            //     left: 1,
            //     blur: 1,
            //     color: '#fff',
            //     opacity: 0.45,
            //   },
            // },
            // dropShadow: {
            //   enabled: false,
            //   top: 1,
            //   left: 1,
            //   blur: 1,
            //   color: '#fff',
            //   opacity: 0.45,
            // },
          },
          tooltip: {
            x: {
              show: true
            },
            y: {
              show: true
            }
          }
        }
      }
    }
  },
  created() {
    console.log('created graph', this.data)
  }
}
</script>
