var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    attrs: {
      "id": "dashboard-ecommerce"
    }
  }, [_c('b-row', {
    staticClass: "match-height"
  }, [_c('b-col', {
    attrs: {
      "lg": "4",
      "md": "12",
      "cols": "12"
    }
  }), _c('b-col', {
    attrs: {
      "lg": "8",
      "md": "12",
      "sm": "12",
      "cols": "12"
    }
  })], 1), _c('b-row', {
    staticClass: "match-height"
  }, [_c('b-col', {
    attrs: {
      "lg": "12",
      "md": "12",
      "cols": "12"
    }
  }, [_vm.loading ? _c('b-progress', {
    class: 'progress-bar-info',
    attrs: {
      "animated": "",
      "show-progress": "",
      "variant": "primary",
      "max": 100,
      "height": "1.3rem",
      "striped": true
    }
  }, [_c('b-progress-bar', {
    attrs: {
      "value": 100,
      "label": "In Progress",
      "variant": "info"
    }
  })], 1) : _vm._e(), _c('b-row', {
    staticClass: "match-height"
  }, [_c('b-col', [_c('statistic-card-horizontal', {
    attrs: {
      "icon": "AnchorIcon",
      "color": "info",
      "statistic": _vm.kapalRencanaSandar || 0,
      "statistic-title": "Jumlah Kapal Rencana Sandar. (today)"
    }
  }), _c('statistic-card-horizontal', {
    attrs: {
      "icon": "AnchorIcon",
      "color": "warning",
      "statistic": _vm.kapalSandar || 0,
      "statistic-title": "Jumlah Kapal Yang Sandar. (today)"
    }
  }), _c('statistic-card-horizontal', {
    attrs: {
      "icon": "AnchorIcon",
      "color": "success",
      "statistic": _vm.kapalBerangkat || 0,
      "statistic-title": "Jumlah Kapal Yang Berangkat. (today)"
    }
  }), _c('statistic-card-horizontal', {
    attrs: {
      "icon": "AnchorIcon",
      "color": "danger",
      "statistic": _vm.kapalCancel || 0,
      "statistic-title": "Jumlah Kegiatan Yang Dibatalkan. (today)"
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "8",
      "md": "8",
      "cols": "12"
    }
  }, [_c('div', [_c('GraphRiver', {
    key: _vm.componentKey.boat,
    attrs: {
      "data": _vm.data.statisticRiver,
      "statistics-river-items": _vm.data.statisticsRiverItems
    }
  })], 1)])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }