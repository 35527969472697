<template>
  <section id="dashboard-ecommerce">
    <b-row class="match-height">
      <b-col lg="4" md="12" cols="12">
        <!-- <b-card v-if="data.congratulations" class="card-congratulation-medal"> -->
        <!-- <div v-if="data.congratulations.isHost === true">
            <v-select
              v-model="hostCondition.ignoreGroup"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="ignoreGroupData"
              :reduce="(option) => option.value"
              :selectable="option => ! option.value.includes('null')"
              label="text"
              item-value="value"
              item-text="text"
            />
            <hr>
            <v-select
              v-if="hostCondition.ignoreGroup !== 'true'"
              v-model="hostCondition.loadTypeForm"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="loadTypeFormData"
              :reduce="(option) => option.value"
              :selectable="option => ! option.value.includes('null')"
              label="text"
              placeholder="Select Form Type"
              item-value="value"
              item-text="text"
            />
            <hr>
            <vue-autosuggest
              v-if="hostCondition.ignoreGroup !== 'true' && hostCondition.loadTypeForm"
              id="vi-group-name"
              host-condition-group-account-
              :suggestions="[{ data: groupAccountData }]"
              :get-suggestion-value="getSuggestionValue"
              :limit="7"
              :input-props="{
                id: 'autosuggest__input',
                class: 'form-control',
                placeholder: 'Group Account',
              }"
              @input="fetchGroupData($event, 'group/search/')"
            >
              <template slot-scope="{suggestion}">
                <span class="my-suggestion-item">{{ suggestion.item.name }}</span>
              </template>
            </vue-autosuggest>
          </div> -->
        <!-- <div>
            <h5>
              {{ $can('manage', 'qrcode') === true ? 'Welcome' : 'Hi' }} 🎉
              {{ data.congratulations.name }}!
            </h5>
            <b-card-text class="font-small-3">
              {{
                $can('manage', 'qrcode') === true
                  ? '-'
                  : 'Semoga hari anda menyenangkan.'
              }}
            </b-card-text>
            <h3 class="mb-75 mt-2 pt-50">
              <b-link>Keep on safety first</b-link>
            </h3>
            <b-img
              :src="require('@/assets/images/illustration/badge.svg')"
              class="congratulation-medal"
              alt="Medal Pic"
            />
          </div> -->
        <!-- </b-card> -->
      </b-col>
      <b-col lg="8" md="12" sm="12" cols="12">
        <!-- realtime check -->
        <!-- <b-row>
          <b-col lg="3" md="3" cols="6">
            <statistic-card-vertical
              hide-chart
              color="danger"
              icon="AnchorIcon"
              :statistic="`${data.truck_in_area || 0}`"
              statistic-title="IN Area Capacity"
            />
          </b-col>
          <b-col lg="3" md="3" cols="6">
            <statistic-card-vertical
              hide-chart
              color="danger"
              icon="AnchorIcon"
              :statistic="`${data.truck_in_queue_area || 0}`"
              statistic-title="Queue Area Capacity"
            />
          </b-col>

          <b-col lg="3" md="3" cols="6">
            <statistic-card-vertical
              hide-chart
              color="success"
              icon="AnchorIcon"
              statistic="2.1k"
              statistic-title="IN Area Capacity"
            />
          </b-col>
          <b-col lg="3" md="3" cols="6">
            <statistic-card-vertical
              hide-chart
              color="success"
              icon="AnchorIcon"
              statistic="2.1k"
              statistic-title="Queue Area Capacity"
            />
          </b-col>
        </b-row> -->
      </b-col>
    </b-row>

    <b-row class="match-height">
      <!-- Boat -->
      <b-col lg="12" md="12" cols="12">
        <b-progress
          v-if="loading"
          animated
          show-progress
          variant="primary"
          :max="100"
          height="1.3rem"
          :striped="true"
          :class="'progress-bar-info'"
        >
          <b-progress-bar :value="100" :label="`In Progress`" variant="info" />
        </b-progress>
        <b-row class="match-height">
          <b-col>
            <statistic-card-horizontal
              icon="AnchorIcon"
              color="info"
              :statistic="kapalRencanaSandar || 0"
              statistic-title="Jumlah Kapal Rencana Sandar. (today)"
            />
            <statistic-card-horizontal
              icon="AnchorIcon"
              color="warning"
              :statistic="kapalSandar || 0"
              statistic-title="Jumlah Kapal Yang Sandar. (today)"
            />
            <statistic-card-horizontal
              icon="AnchorIcon"
              color="success"
              :statistic="kapalBerangkat || 0"
              statistic-title="Jumlah Kapal Yang Berangkat. (today)"
            />
            <statistic-card-horizontal
              icon="AnchorIcon"
              color="danger"
              :statistic="kapalCancel || 0"
              statistic-title="Jumlah Kegiatan Yang Dibatalkan. (today)"
            />
          </b-col>
          <b-col lg="8" md="8" cols="12">
            <div>
              <GraphRiver
                :key="componentKey.boat"
                :data="data.statisticRiver"
                :statistics-river-items="data.statisticsRiverItems"
              />
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardText,
  BLink,
  BButton,
  BImg
} from 'bootstrap-vue'

import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'
import VSelect from 'vue-select'
import { VueAutosuggest } from 'vue-autosuggest'
import OverlayLoading from '@views/components/OverlayLoading.vue'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
import { getUserData } from '@/auth/utils'
import GraphRiver from './graph.vue'
import useAppConfig from '@core/app-config/useAppConfig'
import useJwt from '@/auth/jwt/useJwt'
import '@core/scss/vue/libs/vue-autosuggest.scss'

export default {
  components: {
    StatisticCardHorizontal,
    VueAutosuggest,
    VSelect,
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    BImg,
    BButton,
    GraphRiver,
    StatisticCardVertical,
    OverlayLoading
  },
  data() {
    return {
      data: {
        statisticRiver: {
          series: [
            {
              name: 'Total IN OUT',
              categories: [
                'Senin, 25 Juli',
                'Selasa',
                'Rabu',
                'Kamis',
                'Jumat',
                'Sabtu'
              ],
              data: [0, 20, 5, 30, 15, 45]
            }
          ]
        }
      },
      kapalRencanaSandar: null,
      kapalSandar: null,
      kapalBerangkat: null,
      kapalCancel: null,
      loading: false,
      timeoutDebounce: null,
      componentKey: {
        host: 1,
        statistic: 10,
        truck: 20,
        boat: 30,
        qrcode: 40
      },
      hostCondition: {
        ignoreGroup: 'true',
        loadTypeForm: null, // selected
        groupAccount: null
      },
      ignoreGroupData: [
        { value: 'null', text: 'Filter BY' },
        { value: 'true', text: 'ALL' },
        { value: 'false', text: 'Specify' }
      ],
      loadTypeFormData: [
        { value: 'null', text: 'Select Form Type' },
        { value: 'form1', text: 'Form 1  (T1)' },
        { value: 'form2', text: 'Form 2  (T2)' },
        { value: 'form3', text: 'Form 3  (T3)' }
      ],
      groupAccountData: []
    }
  },

  setup() {
    const { anIncomingNotificationRiver } = useAppConfig()
    return {
      anIncomingNotificationRiver /* FirebaseFCM */
    }
  },
  watch: {
    anIncomingNotificationRiver() {
      console.log(
        'anIncomingNotificationRiver ::',
        this.anIncomingNotificationRiver
      )
      let high_light_data = this.anIncomingNotificationRiver.data[`action`]
      let will_be_refresh = [
        'Kapal Sandar',
        'Telah Sandar',
        'Telah Berangkat',
        'Membatalkan Kegiatan',
        'Berencana Sandar',
        'Pindah Jetty'
      ]
      let go_refresh = will_be_refresh.some((data) =>
        high_light_data.includes(data)
      )
      console.log('vgo_refresh::', go_refresh)
      if (go_refresh) {
        this.loading = true
        this.fetchData()
      }
    },
    'hostCondition.ignoreGroup': function () {
      this.fetchData()
    },
    'hostCondition.loadTypeForm': function () {
      this.fetchData()
    },
    'hostCondition.groupAccount': function () {
      console.log('look up by groupAccount')
      this.fetchData()
    }
  },
  mounted() {
    document.body.style.zoom = '100%'
  },
  destroyed() {
    document.body.style.zoom = '100%'
  },
  created() {
    this.loading = true
    this.fetchData()
  },
  methods: {
    getSuggestionValue(suggestion) {
      if (this.hostCondition.groupAccount !== suggestion.item.name) {
        this.hostCondition.groupAccount = suggestion.item.name
        console.log('suggestion:', suggestion)
        this.fetchData()
      } else {
        console.log('already on suggestion:', suggestion)
      }
      return this.hostCondition.groupAccount
    },
    async fetchData() {
      await this.$http.get('/ecommerce/data').then(async (response) => {
        this.data = response.data

        const userData = getUserData()
        this.data.congratulations.name = userData.full_name
        // const isHost = this.$can('manage', 'all') || this.$can('manage', 'qrcode')
        // this.data.congratulations.isHost = isHost
        // this.componentKey.host += 1

        // const keyword = isHost === true ? this.hostCondition : {
        //   ignoreGroup: false,
        // }

        // this.data.truck_in_area = 0
        // this.data.truck_in_queue_area = 0
        this.componentKey.boat += 1

        if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
        this.timeoutDebounce = setTimeout(() => {
          useJwt.http.get('vw_monitor_jetty/jetty_graph').then((reports) => {
            const resData = reports.data
            const { monitors } = resData
            this.data.statisticRiver = monitors.statisticRiver
            this.kapalRencanaSandar = monitors.kapal_rencana_sandar
            this.kapalSandar = monitors.kapal_sandar
            this.kapalBerangkat = monitors.kapal_berangkat
            this.kapalCancel = monitors.kapal_cancel

            this.componentKey.boat += 1
            this.loading = false
          })
        }, 300)
      })
    },
    fetchGroupData(keyword, uri) {
      if (keyword) {
        clearTimeout(this.timeoutDebounce)
        this.timeoutDebounce = setTimeout(() => {
          useJwt.http

            .get(`${uri}${keyword}`)
            .then((response) => {
              this.groupAccountData = response.data.groups
            })
            .catch((e) => {
              //
            })
        }, 300)
      }
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
